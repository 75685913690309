"use client";

import React, {
  Suspense,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import "./i18n";
import "normalize.css";
import Header from "./components/header";
import Footer from "./components/footer";

import { Provider, useDispatch } from "react-redux";
import {
  persistor,
  store,
  syncDataToAllBrowserTabs,
  useTypedSelector,
} from "./lib/state/store";
import { App, ConfigProvider } from "antd";
import { AndtdCssCustomization } from "./lib/css/antd-css-customization";

import { configurationApi } from "./lib/api/configuration";
import {
  updateCategories,
  updateFlatCategoriesForNavbar,
  updateCategoriesTrans,
} from "./lib/state/category.slice";
import { convertCategoriesToMenuItems } from "./lib/util/api.data.helper";
import { loadLanguages } from "./lib/state/lang.slice";
import { apiClient } from "./lib/util/http";
import {
  WebSocketContext,
  WebSocketProvider,
} from "./lib/ws/websocket.context";
import { PersistGate } from "redux-persist/integration/react";
import { useRouter } from "next/navigation";
import { StaticLinks } from "./lib/route/link";
import { userApi } from "./lib/api/user";
import { setToken, setUserProfile } from "./lib/state/user.slice";

export function MainLayout({ children }: RootLayoutProps) {
  return (
    <React.StrictMode>
      <App>
        <Provider store={store}>
          <PersistGate persistor={persistor} loading={null}>
            <WebSocketProvider>
              <ConfigProvider
                theme={{
                  token: AndtdCssCustomization,
                }}
              >
                <Suspense>
                  <div
                    style={
                      {
                        // backgroundImage: "url(/background_orange_header.png)",
                      }
                    }
                  >
                    <Header />
                    <div
                      style={{
                        minHeight: "80vh",
                        background: "#f9f9f9",
                      }}
                    >
                      <ReactRoot>{children}</ReactRoot>
                    </div>
                    <div
                      className="w-full flex justify-center"
                      style={{ background: "#f9f9f9" }}
                    >
                      <div className="max-w-screen-xl1400 bg-white p-1 mt-4 pt-4 flex-grow pb-10">
                        <Footer />
                      </div>
                    </div>
                  </div>
                </Suspense>
              </ConfigProvider>
            </WebSocketProvider>
          </PersistGate>
        </Provider>
      </App>
    </React.StrictMode>
  );
}

export default function ReactRoot({ children }: RootLayoutProps) {
  const { closeWs } = useContext(WebSocketContext);
  const router = useRouter();
  const dispatch = useDispatch();
  const [data, setData] = useState<
    Pick<
      APIConfigurationResponseType,
      "categories" | "categoriesTrans" | "languages"
    >
  >({
    categories: [],
    categoriesTrans: [],
    languages: [],
  });

  useEffect(() => {
    configurationApi.getConfigurationCategoriesLanguages().then((data) => {
      setData((w) => ({ ...w, ...data.data }));
    });
  }, []);

  const hiarachechyCategories = useMemo(() => {
    return convertCategoriesToMenuItems(data.categories);
  }, [data]);

  const flatCategoriesForNavbar = useMemo(() => {
    return data.categories
      .filter((w) => w.showOnNavbar)
      .map(
        (w) =>
          ({
            key: `${w.categoryId}`,
            label: w.name || "",
            collectionId: `${w.collectionId || ""}`,
            externalId: `${w.externalId || ""}`,
          } as MenuItem)
      );
  }, [data]);

  useEffect(() => {
    hiarachechyCategories && dispatch(updateCategories(hiarachechyCategories));
    flatCategoriesForNavbar &&
      dispatch(updateFlatCategoriesForNavbar(flatCategoriesForNavbar));
    data.categoriesTrans &&
      dispatch(updateCategoriesTrans(data.categoriesTrans));
    data.languages && dispatch(loadLanguages(data.languages));
  }, [hiarachechyCategories, flatCategoriesForNavbar, data, dispatch]);

  // 从store读取token并设置到httpClient，此token源自登录之后的token设置
  // TODO token失效如何处理？增加一个API接口来检查用户登录状态是否依旧有效
  const token = useTypedSelector((state) => state.user.token);
  useEffect(() => {
    if (token) {
      apiClient.setToken(token);
    }
  }, [token]);

  // 为apiClient添加跳转登录回调函数
  useEffect(() => {
    apiClient.setFnRedirect(async () => {
      await userApi.logout();
      dispatch(setUserProfile(null));
      dispatch(setToken({ token: "" }));
      apiClient.setToken("");
      syncDataToAllBrowserTabs();
      // 关闭websocket连接
      closeWs();
      router.push(`${StaticLinks.Login}?redirectTo=${window.origin}`);
    });
  }, [closeWs, dispatch, router]);

  return <>{children}</>;
}
