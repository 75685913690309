import { useTranslation } from "react-i18next";
import "./footer.css";
import Image from "next/image";
import {
  FacebookOutlined,
  InstagramOutlined,
  WhatsAppOutlined,
} from "@ant-design/icons";
import { Typography } from "antd";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <footer className="footer">
      <div className="grid grid-cols-5">
        <div className="col-span-1 pl-8">
          <Image src="/logo-white.png" alt="logo" width="140" height="140" />
        </div>
        <div className="col-span-1">
          <Typography.Title level={5}>
            {t("company_hangzhouyoumai")}
          </Typography.Title>
          <ul>
            <li>{t("company_address")}</li>
            <li>{t("company_tel")}</li>
            <li>{t("company_email")}</li>
          </ul>
        </div>
        <div className="col-span-1">
          <Typography.Title level={5}>{t("company_info")}</Typography.Title>
          <ul>
            <li>
              <a href="/company/company-introduction.html">
                {t("company_about")}
              </a>
            </li>
            <li>
              <a href="/company/scope-of-business.html">
                {t("company_service")}
              </a>
            </li>
            <li>
              <a href="/company/contact.html">{t("company_contact")}</a>
            </li>
          </ul>
        </div>
        <div className="col-span-1">
          <Typography.Title level={5}>{t("customer_service")}</Typography.Title>
          <ul>
            <li>
              <a
                href="/company/platform-fee.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("serviceFares")}
              </a>
            </li>
            <li>
              <a
                href="/company/custom-clearance.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("customClearance")}
              </a>
            </li>
            <li>
              <a
                href="/company/individual.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("customer_individuals")}
              </a>
            </li>
            <li>
              <a
                href="/company/retail.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("customer_retail")}
              </a>
            </li>
            <li>
              <a
                href="/company/oem-requirements.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("customer_oem")}
              </a>
            </li>
            <li>
              <a
                href="/company/vip.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("customer_vip")}
              </a>
            </li>
          </ul>
        </div>
        <div className="col-span-1">
          <Typography.Title level={5}>{t("support_service")}</Typography.Title>
          <ul>
            <li>
              <a
                href="/company/how-to-order.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("support_how_to_order")}
              </a>
            </li>
            <li>
              <a
                href="/company/payment.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("support_payment")}
              </a>
            </li>
            <li>
              <a
                href="/company/delivery-information.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("support_logistics")}
              </a>
            </li>
            <li>
              <a
                href="/company/product-return.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("support_return")}
              </a>
            </li>
            <li>
              <a
                href="/company/product-refund.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("support_refund")}
              </a>
            </li>
            <li>
              <a
                href="/company/shopping-points.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("support_bonus_points")}
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="grid grid-cols-5 footer-bottom">
        <div className="col-span-1 footer-section logo">&nbsp;</div>
        <div className="col-span-3 flex justify-start">
          <p>
            &copy;{t("copyright")}
            &nbsp;&nbsp;&nbsp;
            <br />
            <a
              href="/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-xxs"
            >
              {t("privacy_policy")}
            </a>
            &nbsp;&nbsp;|&nbsp;&nbsp;
            <a
              href="/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-xxs"
            >
              {t("terms_conditions")}
            </a>
          </p>
        </div>
        <div className="col-span-1 flex gap-2 items-start">
          <FacebookOutlined size={24} />
          <InstagramOutlined size={24} />
          <WhatsAppOutlined size={24} />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
